import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import Module from '../../responsibility/searchModule';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'EntityMapping',
  components: {
    DatePicker,
    Module
  },
  props: ['showEntityMappingForm', 'sobMappingListRow'],
  data() {
    return {
      le_name: '',
      le_sob_mpng_id: null,
      le_entity_mpng: [
        {
          end_date: null,
          start_date: null,
          module_id: null,
          module_name: null,
          le_entity_mpng_id: 0,
          period_closed_level_code: null,
          period_closed_level_mean: null
        }
      ],
      le_entity_mpng_fields: [
        'module_name',
        { key: 'period_closed_level_mean', label: 'Period Closed Level' },
        'start_date',
        'end_date',
        'add',
        'remove'
      ],
      entityMappingFormVisible: false,
      showModuleList: false,
      showValueSetModal: false,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      editMode: true
    };
  },
  mounted() {
    this.entityMappingFormVisible = true;
    if (this.sobMappingListRow) {
      this.le_sob_mpng_id = this.sobMappingListRow.le_sob_mpng_id;
      this.le_name = this.sobMappingListRow.le_name;
      this.getLeMappingData();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditLeMappingData();
        }
      }
    });
  },
  methods: {
    getLeMappingData() {
      this.$store
        .dispatch('sob/getSobLeMappingList', this.le_sob_mpng_id)
        .then(response => {
          if (
            response.status === 200 &&
            response.data.data.le_entity_mpng.length > 0
          ) {
            const mappingList = response.data.data.le_entity_mpng.map(data => {
              data.start_date = data.start_date
                ? new Date(data.start_date)
                : null;
              data.end_date = data.end_date ? new Date(data.end_date) : null;
              return data;
            });
            this.le_entity_mpng = mappingList;
          }
        });
    },
    addEditLeMappingData() {
      const getFilterSobMappingArr = this.le_entity_mpng.filter(
        data => data.module_id !== null
      );
      const getSobMappingArr = getFilterSobMappingArr.map(data => {
        return {
          module_id: data.module_id,
          le_entity_mpng_id: data.le_entity_mpng_id,
          period_closed_level_code: data.period_closed_level_code,
          start_date: commonHelper.formattedDate(data.start_date),
          end_date: commonHelper.formattedDate(data.end_date)
        };
      });
      if (getSobMappingArr && getSobMappingArr.length > 0) {
        const payload = {
          le_entity_mpng: getSobMappingArr,
          le_sob_mpng_id: this.le_sob_mpng_id
        };
        this.loader = true;
        this.$store
          .dispatch('sob/addEditSobLeMappingList', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
              const mappingList = response.data.data.le_entity_mpng.map(
                data => {
                  data.start_date = data.start_date
                    ? new Date(data.start_date)
                    : null;
                  data.end_date = data.end_date
                    ? new Date(data.end_date)
                    : null;
                  return data;
                }
              );
              this.le_entity_mpng = mappingList;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    closeEntityMappingForm() {
      this.entityMappingFormVisible = false;
      this.$emit('closeEntityMappingForm');
    },
    openModuleList(flag, index) {
      this.showModuleList = flag;
      this.le_entity_mpng_index = index;
    },
    selectedRow(item) {
      this.le_entity_mpng[this.le_entity_mpng_index].module_name =
        item.module_name;
      this.le_entity_mpng[this.le_entity_mpng_index].module_id = item.module_id;
      this.showModuleList = false;
    },
    chooseValueset(field, index) {
      if (field === 'period_closed_level') {
        this.openValueSetModal(
          appStrings.VALUESETTYPE.PERIOD_CLOSED_LEVEL,
          index
        );
      }
    },
    openValueSetModal(vsetCode, index) {
      this.le_entity_mpng_index = index;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.PERIOD_CLOSED_LEVEL) {
        this.le_entity_mpng[
          this.le_entity_mpng_index
        ].period_closed_level_mean = item.value_meaning;
        this.le_entity_mpng[
          this.le_entity_mpng_index
        ].period_closed_level_code = item.value_code;
      }
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.le_entity_mpng.push({
          end_date: null,
          start_date: null,
          module_id: null,
          module_name: null,
          le_entity_mpng_id: 0,
          period_closed_level_code: null,
          period_closed_level_mean: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.le_entity_mpng.splice(index, 1);
      }
    },
    disabledDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
