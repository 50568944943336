import commonHelper from '@/app/utility/common.helper.utility';
import addEditSob from './addEditSob';
import { ModelSelect } from 'vue-search-select';
import flexfield from '../flexfield';
import currency from '../currency';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'calendar',
  components: {
    addEditSob,
    ModelSelect,
    flexfield,
    currency
  },
  watch: {
    currentPage: function() {
      this.getSobList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getSobList();
    }
  },
  data() {
    return {
      sobData: null,
      setTimeVsetCode: null,
      showValueSetModal: false,
      compName: null,
      showCommanModal: false,
      calendarDetailId: null,
      payload: {},
      unsubscribe: null,
      showAddCalender: false,
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      tab: null,
      sobName: null,
      selectedFlexField: {
        id: null,
        name: null
      },
      selectedCurrency: {
        id: null,
        name: null
      },
      selectedAccounting: {
        id: null,
        name: null
      },
      calendar: {
        name: null,
        description: null
      },
      sobList: [],
      sobFields: [
        {
          key: 'sob_name',
          label: 'SOB Name'
        },
        {
          key: 'flexfield_name',
          label: 'FlexField'
        },
        {
          key: 'calendar_name',
          label: 'Calender Name'
        },
        {
          key: 'currency_name',
          label: 'Currency'
        },
        {
          key: 'accounting_method_name',
          label: 'Accounting Method'
        }
      ],
      selectedCalName: {
        value: null,
        text: null
      },
      calList: []
    };
  },
  validations: {
    selectedFlexField: {
      name: { required }
    },
    selectedCurrency: {
      name: { required }
    },
    selectedAccounting: {
      name: { required }
    },
    selectedCalName: {
      text: { required }
    }
  },
  mounted() {
    this.getCalenderLovList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.sobData = null;
          this.showAddCalender = true;
        }
        if (actionName === 'download' && !this.showAddCalender) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'sob/getSOBList',
            'sob',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'currency') {
        return (this.childName = 'Currency');
      }
    },
    getSobList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          flexfield_id: this.selectedFlexField.id,
          currency_id: this.selectedCurrency.id,
          calendar_hdr_id: this.selectedCalName.value,
          accounting_method_id: this.selectedAccounting.id,
          sob_name: this.sobName
        };
        this.loader = true;
        this.$store
          .dispatch('sob/getSOBList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.sobList = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getCalenderLovList() {
      const payload = {
        calendar_name: ''
      };
      this.$store.dispatch('sob/getCalenderLov', payload).then(response => {
        if (response.status === 200) {
          const calendarList = response.data.data.map(data => {
            return {
              value: data.calendar_hdr_id,
              text: data.calendar_name
            };
          });
          this.calList = calendarList;
        }
      });
    },
    clear() {
      this.sobName = null;
      this.selectedFlexField = {
        id: null,
        name: null
      };
      this.selectedCurrency = {
        id: null,
        name: null
      };
      this.selectedAccounting = {
        id: null,
        name: null
      };
      this.calendar = {
        name: null,
        description: null
      };
      this.selectedCalName = {
        value: null,
        text: null
      };
      this.sobList = [];
      this.totalRows = null;
    },
    rowSelected(item) {
      this.sobData = item;
      this.showAddCalender = true;
    },
    hideModal() {
      this.showAddCalender = false;
    },
    showHideCommonModal(flag, compName) {
      this.showCommanModal = flag;
      this.compName = compName;
      this.setChildName(this.compName);
    },
    selectedModalData(item) {
      if (this.compName === 'currency') {
        this.selectedCurrency.id = item.currency_id;
        this.selectedCurrency.name = item.currency_name;
      }
      this.showCommanModal = false;
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.tab = vsetCode;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.tab == 'ACCOUNTING_METHOD') {
        this.selectedAccounting.id = item.accounting_method_id;
        this.selectedAccounting.name = item.accounting_method_name;
      } else if (this.tab == 'Flexfield') {
        this.selectedFlexField.id = item.flexfield_id;
        this.selectedFlexField.name = item.flexfield_name;
      }
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      
      if (vsetCode === this.selectedFlexField.id) {
        this.selectedFlexField = {
          name: null,
          id: null
        };
      } else if (vsetCode === this.selectedCurrency.id) {
        
        this.selectedCurrency = {
          id: null,
          name: null
        };
      } else if (vsetCode === this.selectedAccounting.id) {
        this.selectedAccounting = {
          id: null,
          name: null
        };
    }
  }

  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
};
