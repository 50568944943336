import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import flexfield from '../../flexfield';
import currency from '../../currency';
import DatePicker from 'vue2-datepicker';
import entityMapping from '../entityMapping';
export default {
  name: 'addEditCalendar',
  props: ['sobData'],
  components: {
    ModelSelect,
    flexfield,
    currency,
    DatePicker,
    entityMapping
  },
  data() {
    return {
      sobMappingIndex: null,
      setTimeVsetCode: null,
      showCommanModal: false,
      showEntityMappingForm: false,
      sobMappingListRow: null,
      compName: null,
      childName: null,
      vsetCode: null,
      showValueSetModal: null,
      unsubscribe: null,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      sobId: 0,
      sobName: null,
      enterablePeriod: false,
      selectedCalName: {
        value: null,
        text: null
      },
      calList: [],
      selectedFlexField: {
        id: null,
        name: null
      },
      selectedCurrency: {
        id: null,
        name: null
      },
      selectedAccounting: {
        id: null,
        name: null
      },
      sobMappingList: [
        {
          le_name: null,
          le_id: null,
          primary_flag: false,
          le_sob_mpng_id: 0
        }
      ],
      sobMappingFields: [
        {
          key: 'le_name',
          label: 'LE Name'
        },
        {
          key: 'primary_flag'
        },
        {
          key: 'entity_mapping'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  validations: {
    sobName: {
      required
    }
  },
  mounted() {
    this.getCalenderLovList();
    if (this.sobData) {
      this.setSobData();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'save' &&
          this.editMode &&
          !this.showEntityMappingForm
        ) {
          this.addEditSob();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.sobId });
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'flexField') {
        return (this.childName = 'FlexField');
      } else if (tab === 'currency') {
        return (this.childName = 'Currency');
      }
    },
    addEditSob() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          accounting_method_id: this.selectedAccounting.id,
          calendar_hdr_id: this.selectedCalName.value,
          currency_id: this.selectedCurrency.id,
          flexfield_id: this.selectedFlexField.id,
          future_enterable_period: this.enterablePeriod,
          sob_id: this.sobId,
          sob_name: this.sobName
        };
        this.loader = true;
        this.$store
          .dispatch('sob/addEditSob', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.sobId = response.data.data.gl_sob_id;
              this.addEditSobMapping(response.data.data.gl_sob_id);
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.addEditSobMapping(this.sobId);
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    addEditSobMapping(sobId) {
      const getFilterSobMappingArr = this.sobMappingList.filter(
        data => data.le_name !== null
      );
      const getSobMappingArr = getFilterSobMappingArr.map(data => {
        return {
          le_id: data.le_id,
          le_sob_mpng_id: data.le_sob_mpng_id,
          primary_flag: data.primary_flag
        };
      });
      if (getSobMappingArr && getSobMappingArr.length > 0) {
        const payload = {
          le_mpng_list: getSobMappingArr,
          sob_id: sobId
        };
        this.loader = true;
        this.$store
          .dispatch('sob/addEditSobMapping', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    setSobData() {
      if (this.sobData) {
        this.sobId = this.sobData.gl_sob_id;
        this.enterablePeriod = this.sobData.future_enterable_period;
        this.sobName = this.sobData.sob_name;
        this.selectedCalName = {
          value: this.sobData.calendar_hdr_id,
          text: this.sobData.calendar_name
        };
        this.selectedFlexField = {
          id: this.sobData.flexfield_id,
          name: this.sobData.flexfield_name
        };
        this.selectedCurrency = {
          id: this.sobData.currency_id,
          name: this.sobData.currency_name
        };
        this.selectedAccounting = {
          id: this.sobData.accounting_method_id,
          name: this.sobData.accounting_method_name
        };
        this.getSobMappingData(this.sobId);
      }
    },
    getSobMappingData(sobId) {
      this.$store.dispatch('sob/getSobMappingList', sobId).then(response => {
        if (response.status === 200 && response.data.data.length > 0) {
          const mappingList = response.data.data.map(data => {
            data.entity_mapping = 'Details';
            return data;
          });
          this.sobMappingList = mappingList;
        }
      });
    },
    getCalenderLovList() {
      const payload = {
        calendar_name: ''
      };
      this.$store.dispatch('sob/getCalenderLov', payload).then(response => {
        if (response.status === 200) {
          const calendarList = response.data.data.map(data => {
            return {
              value: data.calendar_hdr_id,
              text: data.calendar_name
            };
          });
          this.calList = calendarList;
        }
      });
    },
    showHideCommonModal(flag, compName) {
      this.showCommanModal = flag;
      this.compName = compName;
      this.setChildName(this.compName);
    },
    selectedModalData(item) {
      if (this.compName === 'flexField') {
        this.selectedFlexField.id = item.flexfield_id;
        this.selectedFlexField.name = item.flexfield_name;
      } else if (this.compName === 'currency') {
        this.selectedCurrency.id = item.currency_id;
        this.selectedCurrency.name = item.currency_name;
      }
      this.showCommanModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.sobMappingIndex = index;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'ACCOUNTING_METHOD') {
        this.selectedAccounting.id = item.accounting_method_id;
        this.selectedAccounting.name = item.accounting_method_name;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.sobMappingList[this.sobMappingIndex].le_name = item.org_name;
        this.sobMappingList[this.sobMappingIndex].le_id = item.org_id;
      } else if (this.vsetCode === 'PERIOD_CLOSED_LEVEL') {
        this.sobMappingList[this.sobMappingIndex].period_closed_level_meaning =
          item.value_meaning;
        this.sobMappingList[this.sobMappingIndex].period_closed_level_code =
          item.value_code;
      }
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.sobMappingList.push({
          le_name: null,
          le_id: null,
          end_date: null,
          start_date: null,
          primary_flag: false,
          le_sob_mpng_id: 0,
          period_closed_level_meaning: null,
          period_closed_level_code: null
        });
      }
    },
    disabledDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    removeRow(index) {
      this.sobMappingList.splice(index, 1);
    },
    openCloseEntityMappingModal(rowItem) {
      this.showEntityMappingForm = true;
      this.sobMappingListRow = rowItem;
    },
    closeEntityMappingForm() {
      this.showEntityMappingForm = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.selectedAccounting.value) {
        this.selectedAccounting = {
          text: null,
          value: null
        };
      }
    },
    clearVsetValuesOnGrid(vsetCode, index) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.sobMappingList[index].le_id = null;
        this.sobMappingList[index].le_name = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
};
